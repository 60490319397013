import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

const BlogPostTemplate = ({ data: { post } }) => {
  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} />
      <div>
        {!!post.title && (
        <h1 itemProp="headline">{parse(post.title)}</h1>
        )}
        {!!post.content && (
          <div itemProp="articleBody">{parse(post.content)}</div>
        )}
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
